import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Home.js';
import Test from './Test.js';

export default function App() {
  return (
      <Router>
          <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/clickup-test" element={<Test />} />
          </Routes>
      </Router>
  );
}


