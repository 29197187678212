import Hero from "./Hero";
import Footer from "./Footer";

export default function Home() {
    return (
        <div>
            <Hero />
            <Footer />
        </div>
    );
}